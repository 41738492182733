/** @jsx jsx */
import { jsx } from "theme-ui"
import { animated, useSpring, config } from "react-spring"
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

type ProjectGalleryProps = {
  node: {
    project: any
    folders: any
  }
}

const ProjectGallery = ({ node }: ProjectGalleryProps) => {
  const contentProps = useSpring({ config: config.slow, delay: 1000, from: { opacity: 0 }, to: { opacity: 1 } })

  const project = node.project.replace('/', '')
  const assets = node.folders[project]

  if (assets.length < 2) return (<img width="100%" height="100%"src={assets[0].node.publicURL} />)

  return (
    <animated.div style={contentProps}>
      <Carousel
        arrows
        slidesPerPage={1}
        autoPlay={5000}
        animationSpeed={1000}
        infinite
        breakpoints={{
          640: {
            arrows: false
          }
        }}
      >
        {assets.map(({ node : { publicURL }}) => (
          <img width="100%" height="100%"src={publicURL} />
        ))}
      </Carousel>
    </animated.div>
  )
}

export default ProjectGallery
