import { graphql, useStaticQuery } from "gatsby"

type UseUploadedAssetProps = {
  allFile: {
    edges: {
      node: {
        relativeDirectory: string
        publicURL: string
      }
    }[]
  }
}

const useUploadedAssets = () => {
  const data = useStaticQuery<UseUploadedAssetProps>(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/"}, sourceInstanceName: {eq: "content/projects"}}) {
        edges {
          node {
            relativeDirectory
            publicURL
          }
        }
      }
    }
  `)
  return data.allFile.edges

}

export default useUploadedAssets